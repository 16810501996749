'use strict';
import * as Switch from '@radix-ui/react-switch';
import axios from 'axios';
import cls from 'classnames';
import {AnimatePresence, motion} from 'framer-motion';
import React, {ChangeEvent, forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {useMediaQuery} from 'usehooks-ts';
import {getMakes, getModels, getSeries, Make, Model, Series} from './FitMy4x4';
import {SelectField} from './FormFields/SelectField';
import {Option} from './FormikFields/SelectField';
import {ArrowLeft, ArrowRight} from './ItemSlider';

interface Product {
  code: string;
  description: string;
  image: string;
  name: string;
  price: string;
  salePrice: string;
}

interface Part extends Product {
  quantity: number;
  links: {
    page: string;
  };
}

interface ProductSlide extends Product {
  parts: Part[];
  links: {
    page: string;
    addToQuote: string;
    removeFromQuote: string;
  };
  vehicles: [
    {
      make: string;
      model: string;
      series: string;
    }
  ];
  isInQuote: boolean;
  bundleFiltersValues: string[];
}

type Filter = {
  title: string;
  filterID: string;
  values: Option[];
}


const Slide = motion(forwardRef<HTMLDivElement, {
  slide: ProductSlide,
  placeholderImage?: string,
  showComponents: boolean
}>(({slide, placeholderImage, showComponents, ...props}, ref) => {
  return (
    <div ref={ref} className="flex flex-col items-start px-3 flex-shrink-0 xl:basis-1/3 md:basis-1/2 basis-full" {...props}>
      <img
        src={slide.image || placeholderImage || '/assets/frontend/placeholder_4x4.png'}
        alt={slide.name}
        className={cls('w-full aspect-video', slide.image || placeholderImage ? 'object-cover' : 'object-contain')}
      />
      <div className="flex flex-col gap-4 h-44 my-4">
        <h4 className="text-xl uppercase font-bold text-white line-clamp-3">{slide.name}</h4>
        <p className="text-base text-slate-325 w-[95%] line-clamp-3">{slide.description}</p>
      </div>
      <a href={slide.links.page} className="group text-white font-normal text-xl mb-4 flex gap-2 items-center group:transition-all">
        <span className="group-hover:text-slate-325 transition-all">More info</span>
        <svg className="fill-white group-hover:fill-slate-325 transition-all w-4 h-4" viewBox="0 0 20 20" fill="current"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.666484 11.1667L0.666484 8.83333L14.6665 8.83332L8.24982 2.41666L9.90648 0.759991L19.1465 9.99999L9.90648 19.24L8.24982 17.5833L14.6665 11.1667L0.666484 11.1667Z"
            fill="current"/>
        </svg>
      </a>
      {
        showComponents ? <div className="w-full">
            <div className="text-md uppercase font-bold text-white">Contains</div>
            <hr className="w-full mt-3"/>
            <div className="w-full">
              {
                slide.parts.map((part, index) => (
                  <div key={index} className="grid grid-cols-3 gap-8 border-b border-slate-325/10 py-4 text-sm">
                    <div className="flex flex-col col-span-2">
                      <div className="text-white font-medium">{part.name}</div>
                      <div className="text-slate-325 text-xs font-medium">{part.code}</div>
                    </div>
                    <div className="text-white">{part.quantity}</div>
                  </div>
                ))
              }
            </div>
          </div>
          :
          <div className="flex w-full gap-10 items-center">
            <div className="flex flex-col mt-2">
              <p className="text-xxs uppercase text-slate-350 font-light leading-none tracking-thickest">Price</p>
              <p className="text-white font-normal text-2xl">{slide.salePrice ? 'Now ' + slide.salePrice : slide.price}</p>
            </div>
            <a href={slide.isInQuote ? slide.links.removeFromQuote : slide.links.addToQuote}
               className="bg-green py-4 text-black text-sm font-extrabold w-auto uppercase hover:opacity-80 disabled:opacity-60 inline-flex place-self-end transition-all px-4 ml-auto">
              {slide.isInQuote ? 'Remove from quote' : 'Add to quote'}
            </a>
          </div>
      }
    </div>
  );
}));

export function ProductSlider({slides, placeholderImage, filters, fitMy4x4}: {
  slides: ProductSlide[],
  placeholderImage?: string,
  filters: Filter[],
  fitMy4x4: boolean
}) {
  const [showComponents, setShowComponents] = useState<boolean>(false);
  const progressBar = useRef<HTMLDivElement | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  const [filteredSlides, setFilteredSlides] = useState<ProductSlide[]>(slides);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [direction, setDirection] = useState(0);
  const [showingSlides, setShowingSlides] = useState<ProductSlide[]>([]);
  const isXl = useMediaQuery('(min-width: 1280px)');
  const isMd = useMediaQuery('(min-width: 768px)');

  const amountOfSlidesToShow = useMemo(() => {
    if (isXl) return 3;
    if (isMd) return 2;
    return 1;
  }, [isXl, isMd]);

  const [fetchedSelectedVehicle, setFetchedSelectedVehicle] = useState<boolean>(false);

  const [allMakes, setAllMakes] = useState<Make[] | []>([]);
  const [selectedMake, setSelectedMake] = useState<string>('');

  const [allModels, setAllModels] = useState<Model[] | []>([]);
  const [selectedModel, setSelectedModel] = useState<string>('');

  const [allSeries, setAllSeries] = useState<Series[] | []>([]);
  const [selectedSeries, setSelectedSeries] = useState<string>('');

  useLayoutEffect(() => {
    if(!fitMy4x4) return;
    const selected4x4 = JSON.parse(document.querySelector('[data-react="vehicle"]')?.dataset?.props);
    setSelectedMake(selected4x4.make);
    setSelectedModel(selected4x4.model);
    setSelectedSeries(selected4x4.series);
    getMakes(setAllMakes);
    setFetchedSelectedVehicle(true);
  }, []);

  useEffect(() => {
    getModels(selectedMake, setAllModels);
  }, [selectedMake]);

  useEffect(() => {
    getSeries(selectedMake, selectedModel, setAllSeries);
  }, [selectedMake, selectedModel]);

  useEffect(() => {
    let newSlides = slides;
    if (selectedMake && fitMy4x4) {
      newSlides = newSlides.filter(slide => {
        // if the product has vehicles filter them, if not return slide
        if (!slide.vehicles.length) return slide;
        return slide.vehicles.some(vehicle => {
          return vehicle.make === selectedMake && (vehicle.model === selectedModel || !selectedModel) && (vehicle.series === selectedSeries || !selectedSeries);
        });
      });
      setFilteredSlides(newSlides);
      setCurrentIndex(0);
      setShowingSlides(newSlides.slice(0, amountOfSlidesToShow));
    }
    newSlides = newSlides.filter(slide => {
      return Object.values(selectedFilters).every(filterID => {
        return slide.bundleFiltersValues.includes(filterID);
      });
    });
    setFilteredSlides(newSlides);
    setCurrentIndex(0);
    setShowingSlides(newSlides.slice(0, amountOfSlidesToShow));
  }, [selectedMake, selectedModel, selectedSeries, selectedFilters, amountOfSlidesToShow]);

  useEffect(() => {
    if (!fetchedSelectedVehicle || !fitMy4x4) return;
    axios.post('/api/fitmy4x4/vehicle', {
        makeID: selectedMake,
        modelID: selectedModel,
        seriesID: selectedSeries,
      })
      .catch(error =>
        console.error(error),
      );
  }, [selectedMake, selectedModel, selectedSeries]);

  const sliderProgressWidth = useMemo(() => {
    if (progressBar.current && filteredSlides.length >= amountOfSlidesToShow) {
      const boundingRect = progressBar.current?.getBoundingClientRect();
      const {width} = boundingRect;
      return (width / filteredSlides.length);
    }
    return '100%';
  }, [filteredSlides, progressBar.current]);

  const sliderCurrentPosition = useMemo(() => {
    if (progressBar.current && filteredSlides.length >= amountOfSlidesToShow) {
      const boundingRect = progressBar.current?.getBoundingClientRect();
      const {width} = boundingRect;
      return (width / filteredSlides.length) * currentIndex;
    }
    return 0;
  }, [filteredSlides, progressBar.current, currentIndex]);

  useEffect(() => {
    if (showingSlides.length === 0) {
      setShowingSlides(filteredSlides.slice(0, amountOfSlidesToShow));
    }
    setShowingSlides((currentSlides) => {
      let newSlides = [...currentSlides];
      if (direction > 0) {
        newSlides.splice(0, 1);
        newSlides.push(filteredSlides[(currentIndex + (amountOfSlidesToShow - 1)) % filteredSlides.length]);
        return newSlides;
      } else if (direction < 0) {
        newSlides.pop();
        newSlides.unshift(filteredSlides[(currentIndex) % filteredSlides.length]);
        return newSlides;
      }
      return newSlides;
    });
  }, [currentIndex, filteredSlides]);


  const goToNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredSlides.length);
  };

  const goToPrev = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + filteredSlides.length) % filteredSlides.length);
  };

  return (
    <div className={cls('relative mt-10')}>
      <div className="flex gap-4 text-white mb-5">
        <span>View components</span>
        <Switch.Root onCheckedChange={setShowComponents}
                     className="w-[42px] h-[25px] bg-slate-600 rounded-full relative shadow-[0_2px_10px] shadow-black focus:shadow-[0_0_0_2px] focus:shadow-black data-[state=checked]:bg-green outline-none cursor-default">
          <Switch.Thumb
            className="block w-[21px] h-[21px] bg-white rounded-full shadow-[0_2px_2px] shadow-blackA4 transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]"/>
        </Switch.Root>
      </div>
      <div className="grid lg:grid-cols-4 grid-cols-1 gap-6">
        <div className="flex flex-col gap-4 w-full">
          <h3 className="text-white text-xl uppercase font-bold">Filters:</h3>
          <div className="lg:flex lg:flex-col grid md:grid-cols-4 sm:grid-cols-3 grid-cols-1 gap-6 w-full">
            {
              filters.map((filter, index) => (
                <SelectField
                  key={index}
                  title={filter.title}
                  value={selectedFilters[filter.filterID] || ''}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedFilters((currentFilters) => {
                    let newFilters = {...currentFilters};
                    newFilters[filter.filterID] = e.target.value;
                    return newFilters;
                  })}
                  options={filter.values}
                  clear={() => setSelectedFilters((currentFilters) => {
                    let newFilters = {...currentFilters};
                    delete newFilters[filter.filterID];
                    return newFilters;
                  })}
                />
              ))
            }
            {
              fitMy4x4 && <>
                <h4 className="text-white text-xl uppercase font-bold mt-8 col-span-full">Fit my 4x4:</h4>
                <SelectField title="Make" name="make"
                             onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedMake(e.target.value)}
                             value={selectedMake}
                             clear={() => {
                               setSelectedMake('');
                               setSelectedModel('');
                               setSelectedSeries('');
                             }}
                             options={allMakes.map(make => ({
                               value: make.ID,
                               label: make.Name,
                             }))}/>
                <SelectField title="Model" name="model"
                             value={selectedModel}
                             onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedModel(e.target.value)}
                             clear={() => {
                               setSelectedModel('');
                               setSelectedSeries('');
                             }}
                             options={allModels.map(model => ({
                               value: model.ID,
                               label: model.Name,
                             }))}/>
                <SelectField title="Series" name="series"
                             value={selectedSeries}
                             clear={() => setSelectedSeries('')}
                             onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedSeries(e.target.value)}
                             options={allSeries.map(series => ({
                               value: series.ID,
                               label: series.Name,
                             }))}/>
              </>
            }

          </div>
        </div>

        <div className="lg:col-span-3 flex flex-col relative w-full">
          <h4
            className="text-white font-normal text-xl mb-4 flex gap-2 items-center">{filteredSlides.length} Product{filteredSlides.length > 1 && 's'} found</h4>
          <div className="flex h-full w-full">
            <AnimatePresence initial={false} mode={'popLayout'}>
              {showingSlides.map((item) => (
                <Slide
                  slide={item}
                  layout
                  placeholderImage={placeholderImage}
                  showComponents={showComponents}
                  key={item.code}
                  custom={1}
                  initial={{scale: 0.8, opacity: 0}}
                  transition={{
                    type: 'spring',
                    stiffness: 200,
                    damping: 20,
                    mass: 1.0,
                  }}
                  animate={{scale: 1, opacity: 1}}
                  exit={{scale: 0.8, opacity: 0}}
                />
              )) as React.ComponentProps<typeof Slider>['children']
              }
            </AnimatePresence>
          </div>
          <div className="flex justify-between items-center mt-6">
            <div className="relative w-1/2">
              <motion.div
                transition={{
                  type: 'spring',
                  stiffness: 200,
                  damping: 20,
                  mass: 1.0,
                }}
                animate={{width: sliderProgressWidth, x: sliderCurrentPosition}}
                className="border-b-2 border-green -mb-px z-20"/>
              <div ref={progressBar} className="border-b-2 z-10 border-white opacity-10 w-full"/>
            </div>
            <div className="flex gap-2">
              <button className="z-30 bg-green p-5 disabled:opacity-50" onClick={goToPrev} disabled={filteredSlides.length <= amountOfSlidesToShow}>
                <ArrowRight/>
              </button>
              <button className="z-30 bg-green p-5 disabled:opacity-50" onClick={goToNext} disabled={filteredSlides.length <= amountOfSlidesToShow}>
                <ArrowLeft/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
