'use strict';
import {animated, useSpring} from '@react-spring/web';
import cls from 'classnames';
import React, {MouseEventHandler, useEffect, useRef, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import PageWidthConstraint from './PageWidthConstraint';
import {lg, two_xl} from './TailwindSizes';

interface Slide {
    subTitle: string | undefined;
    title: string | undefined;
    caption: string;
    src: string;
    alt: string;
    youtubeLink: string,
    sliderDuration: number;
    button: {
        title: string;
        url: string;
        anchor: string
        openInNew: boolean;
    } | undefined;
}

interface SliderProps {
    slides: Slide[];
    centerMode: boolean;
}

interface SlickArrow {
    onClick: MouseEventHandler<HTMLButtonElement> | undefined,
    currentSlide: number
}

export function CustomSlider({slides, centerMode}: SliderProps) {
    const sliderRef = useRef<Slider | null>(null);
    const indexBar = useRef(null);
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [positions, setPositions] = useState<number[]>([]);

    const NextArrow = ({onClick, currentSlide}: SlickArrow) => (
        <button className="z-30 bg-green p-5 absolute right-0 bottom-44 disabled:opacity-50 lg:block hidden" disabled={currentSlide === slides.length - 1} onClick={onClick}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.666484 11.1667L0.666484 8.83333L14.6665 8.83332L8.24982 2.41666L9.90648 0.759991L19.1465 9.99999L9.90648 19.24L8.24982 17.5833L14.6665 11.1667L0.666484 11.1667Z" fill="#18191B"/>
            </svg>
        </button>
    );

    const PrevArrow = ({onClick, currentSlide}: SlickArrow) => (
        <button className="z-30 bg-green p-5 absolute right-0 bottom-59 disabled:opacity-50 lg:block hidden" disabled={currentSlide === 0} onClick={onClick}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3335 8.83334V11.1667H5.33352L11.7502 17.5833L10.0935 19.24L0.853516 10L10.0935 0.76001L11.7502 2.41668L5.33352 8.83334H19.3335Z" fill="#18191B"/>
            </svg>
        </button>
    );

    const [springs, api] = useSpring(() => ({
        from: {x: 0},
    }));

    function factorialOfStep(step: number, amount: number) {
        let stepArray = [];
        for (let i = 1; i <= amount; i++) {
            stepArray.push(step * i);
        }
        return stepArray;
    }

    useEffect(() => {
        //@ts-ignore
        const fullIndexBar = indexBar.current?.offsetWidth;
        const step = fullIndexBar / slides.length;
        setPositions([0, ...factorialOfStep(step, slides.length - 1)]);
    }, [indexBar.current]);

    const centerPadding = () => {
        if (window.matchMedia('(min-width:' + two_xl.min + ')').matches) {
            return '300px';
        } else if (window.matchMedia('(min-width:' + lg.min + ')').matches) {
            return '100px';
        } else {
            return '0px';
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        fade: !centerMode,
        centerMode: centerMode,
        centerPadding: centerPadding(),
        speed: 500,
        autoplay: !centerMode || !window.matchMedia('(min-width:' + lg.min + ')').matches,
        autoplaySpeed: (slides[currentSlide]?.sliderDuration && slides[currentSlide].sliderDuration) > 0 ? slides[currentSlide].sliderDuration * 1000 : 5000,
        beforeChange: (current:number, next:number) => setCurrentSlide(next),
        dotsClass: cls('w-full z-20', !centerMode ? 'absolute bottom-1' : '-mt-16'),
        appendDots: (dots: any) => {
            const index = dots.findIndex((dot: any) => dot.props.className === 'slick-active');
            api.start({
                from: {
                    x: springs.x.animation.to,
                },
                to: {
                    x: positions[index],
                },
            });
            return (
                <div>
                    <PageWidthConstraint>
                        {slides[index].subTitle && slides[index].title ?
                            <div className="mb-6 lg:mb-21-3/4">
                                <h3 className="w-full mb-2 lg:w-1/2 text-xs xl:text-sm uppercase font-light leading-none tracking-thickest text-white">{slides[index].subTitle}</h3>
                                <h1 className="font-extrabold w-full lg:w-1/2 text-5xl lg:text-6xl xl:text-8xl uppercase leading-tightest text-white">{slides[index].title}</h1>
                                <h4 className="text-white w-full lg:w-1/2 mt-4 lg:mt-6 text-lg">{slides[index].caption}</h4>
                                <a className="uppercase xl:px-14 px-6 py-3 xl:py-5 bg-green font-bold text-xs xl:text-lg inline-block mt-4 lg:mt-6 text-black-lighter" href={slides[index].button?.url}>
                                    Find out more
                                </a>
                            </div>
                            :
                            <div className={cls(centerMode ? 'mb-8' : 'mb-6 lg:mb-10')}>
                                <h5 className="xl:px-5 px-2 pb-2.5 pt-2 bg-black text-sm xl:text-lg inline-block mt-4 lg:mt-6 text-white">{slides[index].caption}</h5>
                            </div>
                        }
                        <animated.div style={{
                            //@ts-ignore
                            width: indexBar.current?.offsetWidth / dots.length + 'px',
                            ...springs,
                        }} className="border-b-4 border-green -mb-px"/>
                        <div ref={indexBar} className="border-b-2 border-white opacity-20 w-full"/>
                    </PageWidthConstraint>
                </div>);
        },
        // @ts-expect-error
        nextArrow: <NextArrow/>,
        // @ts-expect-error
        prevArrow: <PrevArrow/>,
        slidesToShow: 1,
        slidesToScroll: 1,
    };


    const itemClasses = cls(slides[0]?.subTitle && slides[0]?.title ? 'h-full': 'lg:h-full', 'sm:w-full 2xl:w-full object-cover', centerMode && 'border-26 border-black lg:h-full h-auto');
    return (
        <div className={cls('relative -mb-1.5', centerMode && 'bg-black py-16')}>
            <Slider ref={slider => (sliderRef.current = slider)} {...settings} className="w-full relative h-auto">
              {
                slides.map((item, index) => (
                  <article key={index} className={cls('w-full relative ', slides[index].subTitle && slides[index].title ? 'h-screen' : 'lg:h-203')}>
                    {
                      item.youtubeLink ? (
                          <div className={cls('embed', centerMode && 'border-26 border-black')}>
                            <iframe width="560" height="315" src={item.youtubeLink} title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                                    allowFullScreen></iframe>
                          </div>
                        )
                        :
                        (item.src.includes('.mp4') ?
                            <video className={itemClasses} loop autoPlay muted playsInline>
                              <source src={item.src} type="video/mp4"/>
                            </video>
                            :
                            <img className={itemClasses} src={item.src} alt={item.alt}/>
                        )}
                  </article>
                ))
              }
            </Slider>
            {
                slides.length === 1 && (
                    <div className="absolute bottom-1 w-full z-20">
                        <PageWidthConstraint className="relative z-20">
                            {slides[0].subTitle && slides[0].title ?
                                <div className="mb-6 lg:mb-21-3/4">
                                    <h3 className="w-full mb-2 lg:w-1/2 text-xs xl:text-sm uppercase font-light leading-none tracking-thickest text-white">{slides[0].subTitle}</h3>
                                    <h1 className="font-extrabold w-full lg:w-1/2 text-5xl lg:text-6xl xl:text-8xl uppercase leading-tightest text-white">{slides[0].title}</h1>
                                    <h4 className="text-white w-full lg:w-1/2 mt-4 lg:mt-6 text-lg">{slides[0].caption}</h4>
                                    <a className="uppercase xl:px-14 px-6 py-3 xl:py-5 bg-green font-bold text-xs xl:text-lg inline-block mt-4 lg:mt-6 text-black-lighter" href={slides[0].button?.url}>
                                        Find out more
                                    </a>
                                </div>
                                :
                                <div className={cls(centerMode ? 'mb-8' : 'mb-6 lg:mb-10')}>
                                    <h5 className="xl:px-5 px-2 pb-2.5 pt-2 bg-black text-sm xl:text-lg inline-block mt-4 lg:mt-6 text-white">{slides[0].caption}</h5>
                                </div>
                            }
                        </PageWidthConstraint>
                    </div>)
            }
        </div>
    );
}
